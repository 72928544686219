import { ImageProps } from '.';

export default function DrittoLogo({ width, height }: ImageProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 82 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8654 5.91846C28.0102 6.17525 27.2902 6.46122 26.7248 6.77054L26.5805 5.71478H21.0684V19.445H26.7969V10.5781C27.163 10.4141 27.6248 10.2612 28.1684 10.1234C28.7278 9.98161 29.3181 9.86138 29.9224 9.76567C30.5224 9.67113 31.0878 9.60343 31.6024 9.56549L31.6581 9.56141V5.42413L31.5939 5.42822C30.6793 5.48541 29.7618 5.65058 28.866 5.91904L28.8654 5.91846Z"
        fill="#282828"
      />
      <path
        d="M32.6749 9.70148L32.667 9.76626H34.7319V19.445H40.4604V5.71478H33.1579L32.6749 9.70148Z"
        fill="#282828"
      />
      <path d="M40.46 0H34.2842V4.02288H40.46V0Z" fill="#282828" />
      <path
        d="M62.371 15.6813C61.7716 15.6813 61.3231 15.5651 61.0377 15.3358C60.7553 15.1093 60.6122 14.7136 60.6122 14.1598V9.82407H63.6517L63.8662 5.71539H60.6122V1.62131L54.8837 2.94146V5.71481H49.2788V1.62073L43.5503 2.94087V5.71423H41.7012V9.8229H43.5503V14.5607C43.5503 16.1382 43.9873 17.4076 44.8485 18.3332C45.7109 19.2606 47.1109 19.731 49.0091 19.731C50.4806 19.731 51.7631 19.5168 52.82 19.0948L52.8576 19.0797V15.3696L52.7776 15.3953C52.1903 15.5838 51.6049 15.6795 51.037 15.6795C50.4376 15.6795 49.9891 15.5634 49.7037 15.334C49.4212 15.1076 49.2782 14.7119 49.2782 14.158V9.82232H54.8831V14.5601C54.8831 16.1377 55.3201 17.407 56.1813 18.3326C57.0437 19.26 58.4437 19.7304 60.3419 19.7304C61.8135 19.7304 63.0959 19.5162 64.1529 19.0943L64.1904 19.0791V15.369L64.1104 15.3947C63.5232 15.5832 62.9377 15.6789 62.3698 15.6789L62.371 15.6813Z"
        fill="#282828"
      />
      <path
        d="M79.6062 7.26724C78.2389 6.04689 76.1128 5.42767 73.2867 5.42767C70.4606 5.42767 68.3691 6.04631 67.0121 7.26724C65.6533 8.48875 64.9648 10.2764 64.9648 12.5799C64.9648 14.8835 65.6485 16.695 66.9976 17.9072C68.3449 19.1182 70.4613 19.7322 73.2873 19.7322C76.1134 19.7322 78.2637 19.1182 79.6213 17.9072C80.9801 16.695 81.6686 14.9027 81.6686 12.5799C81.6686 10.2571 80.9747 8.48934 79.6062 7.26666V7.26724ZM71.397 10.2168C71.7807 9.72719 72.417 9.47915 73.2873 9.47915C74.1576 9.47915 74.8286 9.72777 75.2225 10.2174C75.6201 10.7123 75.8213 11.5072 75.8213 12.5799C75.8213 13.6526 75.6201 14.4708 75.2231 14.9564C74.8298 15.4373 74.1783 15.6807 73.2873 15.6807C72.3964 15.6807 71.78 15.4373 71.397 14.957C71.0097 14.4726 70.8134 13.6725 70.8134 12.5799C70.8134 11.4874 71.0097 10.7112 71.3976 10.2168H71.397Z"
        fill="#282828"
      />
      <path d="M6.17581 5.08508H0V9.10797H6.17581V5.08508Z" fill="#282828" />
      <path d="M6.17581 11.1699H0V15.1927H6.17581V11.1699Z" fill="#282828" />
      <path
        d="M15.4971 1.77126C14.1031 1.14854 12.3983 0.832214 10.431 0.832214H6.17578V5.08446H9.7152C10.6952 5.08446 11.5085 5.23211 12.1346 5.52392C12.7534 5.81281 13.2201 6.33399 13.5213 7.07226C13.8255 7.81871 13.9795 8.88907 13.9795 10.253C13.9795 11.6169 13.8104 12.6662 13.4777 13.3736C13.1473 14.0745 12.6661 14.5572 12.0479 14.8087C11.4219 15.0632 10.637 15.1921 9.7152 15.1921H6.17578V19.4444H10.431C13.4352 19.4444 15.7771 18.7049 17.3916 17.2459C19.0074 15.7863 19.8268 13.4337 19.8268 10.253C19.8268 7.99555 19.4535 6.1554 18.7165 4.78389C17.9777 3.41006 16.8946 2.39631 15.4971 1.77067V1.77126Z"
        fill="#282828"
      />
    </svg>
  );
}
