import {
  CheckboxStyle,
  LBTAutocomplete,
  LBTButton,
  LBTCheckbox,
  LBTSelect,
  LBTTextField,
} from '@laborability/components';
import {
  QueryBuilder,
  RuleGroupType,
  ValueEditorProps,
  ValueSelectorProps,
  defaultOperators,
} from 'react-querybuilder';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import { ActionProps } from 'react-querybuilder';
import { createContext, useContext, useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  AttributeMetaType,
  Domain,
  attributesMetaState,
  domainKeysLoaderState,
  domainKeysState,
  domainsLoaderState,
  domainsState,
  getAllDomainKeysCallback,
  getAllDomainsCallback,
  getAttributesMetaByEntityTypeCallback,
} from '@laborability/commons';
import Loader from './Loader';

const OPERATORS = {
  number: ['=', '!=', '<', '>', '<=', '>='],
  string: [
    '=',
    '!=',
    'contains',
    'beginsWith',
    'endsWith',
    'doesNotContain',
    'doesNotBeginWith',
    'doesNotEndWith',
    'in',
    'notIn',
    'between',
    'notBetween',
  ],
  bool: ['='],
  date: ['<', '>', '='],
};

const ErrorContext = createContext<{ [x: string]: string }>({});

function Button({ handleOnClick, label, ...rest }: ActionProps) {
  return (
    <LBTButton
      onClick={handleOnClick}
      children={label}
      variant="outlined"
      size="small"
      {...rest}
    />
  );
}

function Select({
  options,
  value,
  title,
  disabled,
  handleOnChange,
  ...rest
}: ValueSelectorProps) {
  return title === 'Combinators' ? (
    <LBTSelect
      name={title}
      value={value}
      label={title}
      handleChange={handleOnChange}
      items={options.map((item: any) => ({
        id: item.name,
        name: item.label,
        is_pinned: item.is_pinned,
      }))}
      error={false}
      hasFullWidth={false}
      disabled={disabled}
      required
      sx={{ minWidth: '200px', width: 'auto' }}
    />
  ) : (
    <LBTAutocomplete
      name={title}
      value={value}
      label={title}
      handleChange={handleOnChange}
      items={options.map((item: any) => ({
        id: item.name,
        name: item.label,
      }))}
      getItems={async () => {}}
      hasFullWidth={false}
      disabled={disabled}
      required
      sx={{ minWidth: '250px', width: 'min-content' }}
    />
  );
}

function Input({
  value,
  title,
  handleOnChange,
  type,
  disabled,
  field,
  fieldData,
  path,
  ...rest
}: ValueEditorProps) {
  const errors = useContext(ErrorContext);
  const [inputType, setInputType] = useState(value?.type ?? 0);
  const domainKeys = useRecoilValue(domainKeysState);
  const domainValues = useRecoilValue(domainsState);
  const editorName = `schema.rules.${path[0]}.Rule.value.value`;

  const handleChange = (type: number | string, value?: string) => {
    handleOnChange({ type: type === 0 ? null : type, value: value });
  };

  useEffect(() => {
    if (fieldData.type === 'bool' && !value.value)
      handleChange(inputType, 'false');
    // eslint-disable-next-line
  }, [fieldData.type]);

  return (
    <>
      {fieldData.type === 'bool' ? (
        <LBTCheckbox
          variant={CheckboxStyle.PRIMARY}
          checked={value.value === 'true'}
          onChange={val => handleChange(inputType, String(val))}
        />
      ) : (
        <>
          <LBTAutocomplete
            name={'inputType'}
            value={inputType}
            label={'Tipo input'}
            handleChange={e => {
              setInputType(e);
              handleChange(e);
            }}
            items={[
              {
                id: 'Testo libero',
                name: 'Testo libero',
              },
              ...domainKeys
                .filter(item => item.domain !== 'Comune')
                .map(item => ({
                  id: item.domain,
                  name: item.domain,
                })),
            ]}
            getItems={async () => {}}
            hasFullWidth={false}
            required
            sx={{ minWidth: '250px', width: 'min-content' }}
          />
          {inputType === 'Testo libero' ? (
            <LBTTextField
              name={editorName}
              type={type ?? 'text'}
              value={value.value}
              label={title}
              onChange={value => handleChange(inputType, value)}
              disabled={disabled}
              sx={{ minWidth: '200px', width: 'min-content' }}
              error={!!errors?.[editorName]}
              helperText={errors?.[editorName]}
              required
            />
          ) : (
            // <LBTAutocomplete
            <LBTSelect
              name={editorName}
              value={value.value}
              label={title}
              handleChange={e => handleChange(inputType, e)}
              items={domainValues
                .filter(item => item.domain === inputType)
                .map((item: Domain) => ({
                  id: item.value,
                  name: item.value,
                  is_pinned: item.is_pinned,
                }))}
              // minInputLenForFetch={3}
              // hasFullWidth={false}
              hasFullWidth={false}
              disabled={disabled}
              required
              sx={{ minWidth: '200px', width: 'min-content' }}
              error={!!errors?.[editorName]}
              helperText={errors?.[editorName]}
            />
          )}
        </>
      )}
    </>
  );
}

interface Props {
  query: RuleGroupType;
  onQueryChange: (val: RuleGroupType) => void;
  errors: { [x: string]: string };
}

export default function QueryBuilderForm({
  query,
  onQueryChange,
  errors,
}: Props) {
  const getDomainKeys = useRecoilCallback(getAllDomainKeysCallback, []);
  const getDomains = useRecoilCallback(getAllDomainsCallback, []);
  const getAttributes = useRecoilCallback(
    getAttributesMetaByEntityTypeCallback,
    [],
  );
  const attributes = useRecoilValue(attributesMetaState);
  const domainKeysLoader = useRecoilValue(domainKeysLoaderState);
  const domainsLoader = useRecoilValue(domainsLoaderState);
  const isLoading = domainKeysLoader || domainsLoader;

  useEffect(() => {
    getDomainKeys({});
    getDomains({});
    getAttributes({ entity_type: 'user' });
    // eslint-disable-next-line
  }, []);

  return (
    <QueryBuilderMaterial>
      {isLoading && <Loader />}
      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <ErrorContext.Provider value={errors}>
          <QueryBuilder
            controlElements={{
              actionElement: Button,
              valueSelector: Select,
              valueEditor: Input,
            }}
            fields={attributes.map(item => ({
              name: item.attribute_name as string,
              label: item.attribute_name as string,
              defaultValue: { type: null, value: '' },
              type: item.attribute_type,
              operators: OPERATORS[
                item.attribute_type as AttributeMetaType
              ].map(
                (op: string) =>
                  defaultOperators.find(item => item.name === op)!,
              )!,
            }))}
            query={query}
            onQueryChange={onQueryChange}
          />
        </ErrorContext.Provider>
      </div>
    </QueryBuilderMaterial>
  );
}
