import LBTProgressSpinner from '../ProgressSpinner';

interface Props {
  step?: number;
  isStepCompleted?: boolean;
}

export default function BackdropLoader({ step, isStepCompleted }: Props) {
  if (step === 3 && isStepCompleted) return null;
  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        backgroundColor: '#000000CC',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
      }}
    >
      <LBTProgressSpinner />
    </div>
  );
}
