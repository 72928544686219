export const REGEX_REALM_NAME = /^[a-z]+$/;
export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const TEST_DOMAINS = [
  'laborability.com',
  'shritalia.com',
  'growthers.io',
  'growthers.it',
  'growthers.com',
  'adv-player.com',
];

export const APP_ROUTES = {
  HOME: 'home',
  ART_13: 'informativa-ex-art-13-gdpr',
  QUESTIONARY: 'questionary',
};
