import { CallbackInterface } from 'recoil';
import {
  postQuestionFlow,
  getQuestionFlowById,
  getAllQuestionFlow,
  deleteQuestionFlow,
  putQuestionFlow,
  getQuestionFlowStep,
  getQuestionFlowGraph,
  putQuestionFlowGraph,
  getQuestionFlowNode,
} from '../actions';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import { Id, Post, Put, QuestionFlow, QuestionFlowNode } from '../types';
import {
  questionFlowGraphLoaderState,
  questionFlowGraphState,
  questionFlowLoaderState,
  questionFlowNodeLoaderState,
  questionFlowNodeState,
  questionFlowState,
  questionFlowsLoaderState,
  questionFlowsState,
} from '../state';

export const postQuestionFlowCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<QuestionFlow>) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await postQuestionFlow(params);
      set(questionFlowState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Questionario creato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };

export const getQuestionFlowByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await getQuestionFlowById(params);
      set(questionFlowState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };

export const getAllQuestionFlowCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(questionFlowsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllQuestionFlow();
      set(questionFlowsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowsLoaderState, false);
    return res;
  };

export const getQuestionFlowStepCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    let res = undefined;
    try {
      res = await getQuestionFlowStep(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    return res;
  };

export const getQuestionFlowGraphCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionFlowGraphLoaderState, true);
    let res = undefined;
    try {
      res = await getQuestionFlowGraph(params);
      set(questionFlowGraphState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowGraphLoaderState, false);
    return res;
  };

export const getQuestionFlowNodeCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionFlowNodeLoaderState, true);
    let res = undefined;
    try {
      res = await getQuestionFlowNode(params);
      set(questionFlowNodeState, res?.data ?? []);
    } catch (error: any) {
      if (error.response.status !== 404) setSnackbarError(set, error);
    }
    set(questionFlowNodeLoaderState, false);
    return res;
  };

export const putQuestionFlowCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<QuestionFlow>) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await putQuestionFlow(params);
      set(questionFlowState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Questionario modificato con sucesso',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };

export const putQuestionFlowGraphCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<QuestionFlowNode[]>) => {
    set(questionFlowGraphLoaderState, true);
    let res = undefined;
    try {
      res = await putQuestionFlowGraph(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Grafico aggiornato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowGraphLoaderState, false);
    return res;
  };

export const deleteQuestionFlowCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await deleteQuestionFlow(params);
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Questionario cancellato con sucesso',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };
