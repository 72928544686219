import { Put } from '../recoil/types';
import API, { PATH } from './api';

export const authGetAll = async (p: PATH | string, params?: any) => {
  const token = sessionStorage.getItem('token');
  let res;

  try {
    res = await API.get(`/${p}`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
  } catch (error) {
    throw error;
  }

  return res;
};

export const authPost = async (p: PATH | string, postObj: any) => {
  const token = sessionStorage.getItem('token');
  let res;

  try {
    res = await API.post(`/${p}`, postObj, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    throw error;
  }
  return res;
};

export const authPut = async <T>(
  p: PATH | string,
  postObj?: Put<T>,
  forceId?: number | string,
) => {
  const token = sessionStorage.getItem('token');
  const { id, ...rest } = postObj ?? {};
  const isNumericKeyObject = Object.keys(rest).every(
    key => !isNaN(Number(key)),
  );
  let res;

  try {
    const data = isNumericKeyObject ? Object.values(rest) : rest;
    res = await API.put(
      `/${p}/${forceId ?? id ?? ''}`,
      forceId ? postObj : data,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {
    throw error;
  }
  return res;
};

export const authPatch = async <T>(p: PATH | string, postObj?: unknown) => {
  const token = sessionStorage.getItem('token');
  let res;

  try {
    res = await API.patch(`/${p}`, postObj, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    throw error;
  }
  return res;
};

export const authDelete = async (p: PATH | string) => {
  const token = sessionStorage.getItem('token');
  let res;

  try {
    res = await API.delete(`/${p}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    throw error;
  }
  return res;
};

export const noAuthPost = async (p: PATH | string, postObj?: unknown) => {
  let res;
  try {
    res = await API.post(`/${p}`, postObj, {
      headers: { 'Content-Type': `application/json` },
    });
  } catch (error) {
    throw error;
  }
  return res;
};

export const noAuthGetAll = async (p: PATH | string) => {
  let res;

  try {
    res = await API.get(`/${p}`);
  } catch (error) {
    throw error;
  }

  return res;
};
