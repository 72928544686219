import { useRecoilValue } from 'recoil';
import {
  attributeMetaLoaderState,
  attributesMetaLoaderState,
  benefitLoaderState,
  benefitsLoaderState,
  categoryLoaderState,
  categoriesLoaderState,
  companyLoaderState,
  companiesLoaderState,
  companyConfigLoaderState,
  companyConfigsLoaderState,
  contractLoaderState,
  contractsLoaderState,
  domainLoaderState,
  domainsLoaderState,
  domainKeysLoaderState,
  agencyLoaderState,
  agenciesLoaderState,
  entityLoaderState,
  entitiesLoaderState,
  measureLoaderState,
  measuresLoaderState,
  regionsLoaderState,
  provincesLoaderState,
  municipalitiesLoaderState,
  pageActionLoaderState,
  pageActionsLoaderState,
  questionLoaderState,
  questionsLoaderState,
  questionFlowLoaderState,
  questionFlowsLoaderState,
  questionFlowGraphLoaderState,
  questionPageLoaderState,
  questionPagesLoaderState,
  continueFlowLoaderState,
  actionRuleLoaderState,
  actionRulesLoaderState,
  questionRuleLoaderState,
  questionRulesLoaderState,
  tagLoaderState,
  tagsLoaderState,
  userLoaderState,
  usersLoaderState,
  questionFlowNodeLoaderState,
} from '../recoil';

interface Returns {
  isLoading: boolean;
}

export default function useLoading() {
  const attributeMetaLoader = useRecoilValue(attributeMetaLoaderState);
  const attributesMetaLoader = useRecoilValue(attributesMetaLoaderState);
  const benefitLoader = useRecoilValue(benefitLoaderState);
  const benefitsLoader = useRecoilValue(benefitsLoaderState);
  const categoryLoader = useRecoilValue(categoryLoaderState);
  const categoriesLoader = useRecoilValue(categoriesLoaderState);
  const companyLoader = useRecoilValue(companyLoaderState);
  const companiesLoader = useRecoilValue(companiesLoaderState);
  const companyConfigLoader = useRecoilValue(companyConfigLoaderState);
  const companyConfigsLoader = useRecoilValue(companyConfigsLoaderState);
  const contractLoader = useRecoilValue(contractLoaderState);
  const contractsLoader = useRecoilValue(contractsLoaderState);
  const domainLoader = useRecoilValue(domainLoaderState);
  const domainsLoader = useRecoilValue(domainsLoaderState);
  const domainKeysLoader = useRecoilValue(domainKeysLoaderState);
  const agencyLoader = useRecoilValue(agencyLoaderState);
  const agenciesLoader = useRecoilValue(agenciesLoaderState);
  const entityLoader = useRecoilValue(entityLoaderState);
  const entitiesLoader = useRecoilValue(entitiesLoaderState);
  const measureLoader = useRecoilValue(measureLoaderState);
  const measuresLoader = useRecoilValue(measuresLoaderState);
  const regionsLoader = useRecoilValue(regionsLoaderState);
  const provincesLoader = useRecoilValue(provincesLoaderState);
  const municipalitiesLoader = useRecoilValue(municipalitiesLoaderState);
  const pageActionLoader = useRecoilValue(pageActionLoaderState);
  const pageActionsLoader = useRecoilValue(pageActionsLoaderState);
  const questionLoader = useRecoilValue(questionLoaderState);
  const questionsLoader = useRecoilValue(questionsLoaderState);
  const questionFlowLoader = useRecoilValue(questionFlowLoaderState);
  const questionFlowsLoader = useRecoilValue(questionFlowsLoaderState);
  const questionFlowGraphLoader = useRecoilValue(questionFlowGraphLoaderState);
  const questionFlowNodeLoader = useRecoilValue(questionFlowNodeLoaderState);
  const questionPageLoader = useRecoilValue(questionPageLoaderState);
  const questionPagesLoader = useRecoilValue(questionPagesLoaderState);
  const continueFlowLoader = useRecoilValue(continueFlowLoaderState);
  const actionRuleLoader = useRecoilValue(actionRuleLoaderState);
  const actionRulesLoader = useRecoilValue(actionRulesLoaderState);
  const questionRuleLoader = useRecoilValue(questionRuleLoaderState);
  const questionRulesLoader = useRecoilValue(questionRulesLoaderState);
  const tagLoader = useRecoilValue(tagLoaderState);
  const tagsLoader = useRecoilValue(tagsLoaderState);
  const userLoader = useRecoilValue(userLoaderState);
  const usersLoader = useRecoilValue(usersLoaderState);

  return (
    attributeMetaLoader ||
    attributesMetaLoader ||
    benefitLoader ||
    benefitsLoader ||
    categoryLoader ||
    categoriesLoader ||
    companyLoader ||
    companiesLoader ||
    companyConfigLoader ||
    companyConfigsLoader ||
    contractLoader ||
    contractsLoader ||
    domainLoader ||
    domainsLoader ||
    domainKeysLoader ||
    agencyLoader ||
    agenciesLoader ||
    entityLoader ||
    entitiesLoader ||
    measureLoader ||
    measuresLoader ||
    regionsLoader ||
    provincesLoader ||
    municipalitiesLoader ||
    pageActionLoader ||
    pageActionsLoader ||
    questionLoader ||
    questionsLoader ||
    questionFlowLoader ||
    questionFlowsLoader ||
    questionFlowGraphLoader ||
    questionFlowNodeLoader ||
    questionPageLoader ||
    questionPagesLoader ||
    continueFlowLoader ||
    actionRuleLoader ||
    actionRulesLoader ||
    questionRuleLoader ||
    questionRulesLoader ||
    tagLoader ||
    tagsLoader ||
    userLoader ||
    usersLoader
  );
}
