import { QuestionPage } from './questionPage';

export interface QuestionFlow {
  id?: number;
  name?: string;
  step?: number;
  image?: string;
  description?: string;
  pages?: QuestionPage[];
  number_of_pages?: number;
  info_title?: string;
  info_text?: string;
}

export class QuestionFlowClass implements QuestionFlow {
  id = 0;
  name = '';
  step = 0;
  image = '';
  description = '';
  pages = [];
  number_of_pages = 0;
  info_title = '';
  info_text = '';
}

export const QUESTIONARY_STEPS = 9;
export const STATIC_QUESTIONARY_NUMBER = 3;

export interface QuestionFlowGraphArc {
  name: string;
  source_page_id: number;
  target_page_id: number;
  element_id: number;
  element_type: string;
}

export interface QuestionFlowNode {
  id: number;
  label: string;
  height: number;
  width: number;
  position_x: number;
  position_y: number;
}

export interface QuestionFlowGraphNode {
  id: string;
  data: { label: string };
  measured: { height: number; width: number };
  position: { x: number; y: number };
}

export interface QuestionFlowGraphItem {
  name: string;
  page_id: number;
  arcs: QuestionFlowGraphArc[];
  node: QuestionFlowGraphNode;
}
