import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { QuestionaryPageForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  Modal,
  QuestionFlowNode,
  QuestionPage,
  deleteQuestionPageCallback,
  getAllQuestionPageCallback,
  getQuestionFlowByIdCallback,
  putQuestionFlowGraphCallback,
  questionFlowState,
  questionPagesState,
} from '@laborability/commons';
import { useNavigate, useParams } from 'react-router-dom';
import { OpenInNew, Visibility } from '@mui/icons-material';
import { Box } from '@mui/material';
import { APP_ROUTES } from '../Routes';
import { LBTButton, LBTModal } from '@laborability/components';
import { useEffect, useState } from 'react';
import { appBarState } from '../recoil/states/appBar';
import QuestionaryGraph from '../components/QuestionaryGraph';

export function QuestionFlowPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  const deletePage = useRecoilCallback(deleteQuestionPageCallback, []);
  const getFlow = useRecoilCallback(getQuestionFlowByIdCallback, []);
  const updateGraph = useRecoilCallback(putQuestionFlowGraphCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);
  const questionFlow = useRecoilValue(questionFlowState);
  const [modal, setModal] = useState<Modal | null>(null);
  const [newNodes, setNewNodes] = useState<QuestionFlowNode[]>([]);

  const { rowData, handleFetchAll, handleDelete } = useGrid<QuestionPage>({
    state: questionPagesState,
    onFetch: async () => {
      if (id) return await getAllPage({ id });
    },
    onDelete: deletePage,
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'description',
      flex: 3,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'page_order',
      flex: 4,
      sortIndex: 0,
      sort: 'asc',
      filter: 'agNumberColumnFilter',
      headerName: 'Ordine delle domande',
      comparator: (valueA, valueB) => {
        if (valueA == null) return 1;
        if (valueB == null) return -1;
        return valueA - valueB;
      },
      hide: true,
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/${APP_ROUTES.QUESTION_PAGE}/${data.data.id}`)
          }
        >
          <OpenInNew />
        </Box>
      ),
      pinned: 'right',
    },
    {
      field: 'preview',
      headerName: 'Anteprima',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/${APP_ROUTES.PAGE_PREVIEW}/${id}/${data.data.id}`)
          }
        >
          <Visibility />
        </Box>
      ),
      pinned: 'right',
    },
  ];

  useEffect(() => {
    if (id) getFlow({ id: id });
  }, [id]);

  useEffect(() => {
    setAppBarName(
      `Registra pagine${questionFlow?.name ? ` - ${questionFlow.name}` : ''}`,
    );
  }, [questionFlow]);

  return (
    <>
      <GridLayout<QuestionPage>
        tableId="question_flow"
        pageName={'Pagina'}
        columnDefs={columnDefs}
        rowData={rowData}
        handleFetchAll={handleFetchAll}
        handleDelete={handleDelete}
        FormComponent={QuestionaryPageForm}
        recordNameAccessor={item => item.name ?? ''}
        gridFilters={
          <div>
            <LBTButton
              variant="outlined"
              color="primary"
              onClick={() => navigate(`/${APP_ROUTES.QUESTIONARY}`)}
            >
              Indietro
            </LBTButton>
            <LBTButton
              variant="outlined"
              onClick={() =>
                setModal({
                  children: (
                    <QuestionaryGraph
                      setNewQuestions={setNewNodes}
                      flow_id={Number(id)}
                    />
                  ),
                  title: 'Immagine Categoria',
                })
              }
              style={{ marginLeft: '20px' }}
            >
              Grafico
            </LBTButton>
          </div>
        }
      />
      {!!modal && (
        <LBTModal
          open={!!modal}
          onXClose={() => {
            updateGraph({
              id: String(id),
              ...newNodes,
            });
            setModal(null);
          }}
          {...modal}
        />
      )}
    </>
  );
}
